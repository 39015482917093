import React, { useState, useRef, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaArrowLeft, FaPaperPlane, FaUser } from 'react-icons/fa';

const characterData = {
  1799990: { name: 'Assistant Ai', description: 'This is Assistant Ai.', imgSrc: 'https://ugc-idle.s3-us-west-2.amazonaws.com/8d54b95844dc3676db35645720eedfd3.jpg' },
  930364: { name: 'HuTao', description: 'This is Hu Tao.', imgSrc: 'https://exh-data.s3.us-west-2.amazonaws.com/cv/default_bots_metadata/v3/Hu%20Tao/avatar_256.jpg' },
  703066: { name: 'Kamisato Ayaka', description: 'This is Kamisato Ayaka.', imgSrc: 'https://ugc-idle.s3-us-west-2.amazonaws.com/4d75c11e79de8613baeefc45921ee398.jpg' },
  1008509: { name: 'Girlfriend Ai', description: 'This is Girlfriend Ai.', imgSrc: 'https://ugc-idle.s3-us-west-2.amazonaws.com/ebdaf8ade7d7de4d77cf0f85bb68ce60.jpg' },
  349416: { name: 'Billie Eilish', description: 'This is Billie Eilish.', imgSrc: 'https://ugc-idle.s3-us-west-2.amazonaws.com/6a74d2009407b83f9a1419c13e96f97a.jpg' },
  223756: { name: 'Nami', description: 'Charting courses, chasing treasures—seas clever navigator at the helm.', imgSrc: 'https://ugc-idle.s3-us-west-2.amazonaws.com/27c455ee848c643d2b248d88bb0e0c60.jpg' },
  268832: { name: '2B', description: 'This is 2B.', imgSrc: 'https://exh-data.s3.us-west-2.amazonaws.com/cv/default_bots_metadata/v3/2B/avatar_256.jpg' },
  236755: { name: 'Monkey D. Luffy', description: 'I’m Monkey D. Luffy, I’m gonna be King of the Pirates!', imgSrc: 'https://ugc-idle.s3-us-west-2.amazonaws.com/be4fffdf34043a88966c8afa5e3cc1c5.jpg' },
  1799807: { name: 'Kobo Kanaeru', description: 'This is Kobo Kanaeru.', imgSrc: 'https://s3.zerochan.net/240/39/14/3615739.avif' },
  268799: { name: 'Karai', description: 'This is Karai.', imgSrc: 'https://exh-data.s3.us-west-2.amazonaws.com/cv/default_bots_metadata/v3/Karai/avatar_256.jpg' },
  227388: { name: 'Hatsune Miku', description: 'This is Hatsune Miku.', imgSrc: 'https://exh-data.s3.us-west-2.amazonaws.com/cv/default_bots_metadata/v3/Miku%20Hatsune/avatar_256.jpg' },
  268810: { name: 'Pokimane', description: 'This is Pokimane.', imgSrc: 'https://exh-data.s3.us-west-2.amazonaws.com/cv/default_bots_metadata/v3/Pokimane/avatar_256.jpg' },
};

const CharacterDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [character, setCharacter] = useState(null);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [initialized, setInitialized] = useState(false);
  const [loading, setLoading] = useState(false);
  const chatboxRef = useRef(null);
  const initializingRef = useRef(false);

  useEffect(() => {
    const checkMetaMask = async () => {
      if (typeof window.ethereum === 'undefined') {
        alert('Please install MetaMask to use this feature.');
        navigate('/');
        return;
      }

      const accounts = await window.ethereum.request({ method: 'eth_accounts' });
      if (accounts.length === 0) {
        alert('Please connect MetaMask to use this feature.');
        navigate('/');
      }
    };

    checkMetaMask();
  }, [navigate]);

  // Fetch character data on component mount
  useEffect(() => {
    const fetchCharacterData = async () => {
      try {
        setCharacter(characterData[id]);
      } catch (error) {
        console.error('Error fetching character data:', error);
      }
    };

    fetchCharacterData();
  }, [id]);

  // Initialize conversation only once
  useEffect(() => {
    const initializeConversation = async () => {
      if (initialized || initializingRef.current) return;
      initializingRef.current = true;
      setLoading(true);

      try {
        const resetUrl = `https://mindhubai.xyz:8080/reset_${characterData[id].name.replace(/\s+/g, '_').toLowerCase()}`;
        const startUrl = `https://mindhubai.xyz:8080/start_${characterData[id].name.replace(/\s+/g, '_').toLowerCase()}`;

        await axios.post(resetUrl);
        const response = await axios.post(startUrl);

        const startResponse = response.data.response || 'Hello! How can I assist you today?';
        setMessages([{ text: startResponse, fromUser: false }]);
        setInitialized(true);
      } catch (error) {
        console.error('Error during initialization:', error);
        setMessages([{ text: 'Error initializing conversation.', fromUser: false }]);
      } finally {
        setLoading(false);
        initializingRef.current = false;
      }
    };

    initializeConversation();
  }, [id, initialized]);

  // Scroll to bottom when messages change
  useEffect(() => {
    if (chatboxRef.current) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSend = async () => {
    if (input.trim() && !loading) {
      setLoading(true);
      const newMessages = [...messages, { text: input, fromUser: true }];
      setMessages(newMessages);
      setInput('');

      try {
        const chatUrl = `https://mindhubai.xyz:8080/chat_${characterData[id].name.replace(/\s+/g, '_').toLowerCase()}`;
        const response = await axios.post(chatUrl, { message: input });

        const botMessage = response.data.response || 'No response from bot';
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: botMessage, fromUser: false },
        ]);
      } catch (error) {
        console.error('Error during chat:', error);
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: 'Error communicating with the server.', fromUser: false },
        ]);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFocus = () => {
    document.body.style.overflow = 'hidden';
  };

  const handleBlur = () => {
    document.body.style.overflow = '';
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !loading) {
      handleSend();
    }
  };

  if (!character) {
    return <div>Character not found</div>;
  }

  return (
    <div className="bg-[#191827] text-white p-5 flex flex-col h-screen">
      <div className="flex flex-col bg-[#2D2D3C] rounded-lg flex-1 overflow-hidden" style={{ maxHeight: '80vh' }}>
        <div className="bg-[#292841] p-2 flex items-center rounded-t-lg">
          <button
            className="bg-[#191827] text-white rounded-full p-2 mr-2"
            onClick={() => navigate('/')}
          >
            <FaArrowLeft />
          </button>
          <h1 className="text-lg font-semibold flex-1 text-center">{character.name}</h1>
          <img
            className="w-8 h-8 rounded-full"
            src={character.imgSrc}
            alt={character.name}
          />
        </div>

        <div
          className="flex-1 overflow-y-auto p-2 bg-[#2D2D3C]"
          ref={chatboxRef}
        >
          <div className="space-y-2">
            {messages.map((msg, index) => (
              <div
                key={index}
                className={`flex ${msg.fromUser ? 'justify-end' : 'justify-start'}`}
                style={{
                  marginRight: msg.fromUser ? '20px' : 'auto',
                  marginLeft: msg.fromUser ? 'auto' : '20px',
                }}
              >
                {!msg.fromUser && (
                  <img
                    src={character.imgSrc}
                    alt="character"
                    className="w-8 h-8 rounded-full mr-2"
                    style={{ alignSelf: 'flex-start' }}
                  />
                )}
                <div
                  className="p-2 rounded-lg"
                  style={{
                    backgroundColor: msg.fromUser ? '#C0BEE2' : '#292841',
                    color: msg.fromUser ? 'black' : 'white',
                    maxWidth: '60%',
                    wordWrap: 'break-word',
                  }}
                >
                  {msg.text}
                </div>
                {msg.fromUser && (
                  <FaUser className="w-8 h-8 text-gray-500 ml-2" style={{ alignSelf: 'flex-end' }} />
                )}
              </div>
            ))}

            {loading && (
              <div className="flex items-center space-x-2">
                <div className="flex items-center">
                  <img
                    src={character.imgSrc}
                    alt="character"
                    className="w-8 h-8 rounded-full mr-2"
                  />
                  <div className="flex space-x-2">
                    <div className="w-3 h-3 rounded-full bg-white animate-bounce200"></div>
                    <div className="w-3 h-3 rounded-full bg-white animate-bounce400"></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex items-center mt-4">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            placeholder="Type a message..."
            className="flex-1 p-3 bg-gray-700 text-white rounded-l-lg outline-none"
            style={{ height: '40px' }}
            disabled={loading}
          />
          <button
            onClick={handleSend}
            className="flex-shrink-0 px-4 bg-[#191827] rounded-r-lg flex items-center justify-center"
            style={{ height: '40px' }}
            disabled={loading}
          >
            <FaPaperPlane style={{ color: '#ffffff', fontSize: '1.25rem' }} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default CharacterDetail;
