import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import Content from './Content';
import CharacterDetail from './ai/CharacterDetail';

function App() {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  return (
    <Router>
      <div className="h-screen flex flex-col">
        {/* Main content wrapper */}
        <div className="flex flex-grow overflow-hidden">
          {/* Sidebar */}
          <div className={`flex-shrink-0 ${isSidebarOpen ? 'block' : 'hidden'} lg:block`}>
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          </div>

          {/* Content */}
          <div className="flex-grow overflow-y-auto">
            <Routes>
              <Route
                path="/"
                element={
                  <>
                    <Header toggleSidebar={toggleSidebar} />
                    <Content />
                  </>
                }
              />
              <Route
                path="/ai/:id"
                element={
                  <>
                    <Header toggleSidebar={toggleSidebar} fixed={false} />
                    <CharacterDetail />
                  </>
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default App;
