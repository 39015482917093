import React, { useState } from 'react';
import { FaPlusCircle, FaTimes, FaTelegram, FaTwitter, FaDiscord, FaMedium, FaBook, FaCrown, FaStar, FaCheckCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPriceModalOpen, setIsPriceModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const togglePriceModal = (plan = null) => {
    setSelectedPlan(plan);
    setIsPriceModalOpen(!isPriceModalOpen);
  };

  const logoUrl = 'https://botify.ai/static/media/header-logo.edb9aa93e7af1b9b061ba3ef91ca777f.svg'; // Logo URL

  return (
    <>
      <aside
        className={`bg-[#202033] text-white p-4 w-72 h-full fixed lg:relative top-0 lg:top-auto left-0 transform lg:translate-x-0 transition-transform duration-300 z-50 lg:z-auto flex flex-col ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        }`}
      >
        {/* Close button only visible on small screens */}
        <button
          className="text-2xl absolute top-4 right-4 lg:hidden"
          onClick={toggleSidebar}
        >
          <FaTimes />
        </button>

        {/* Logo at the top of the sidebar, only visible on mobile */}
        <div className="flex justify-center items-center mb-6 mt-4 lg:hidden">
          <img src={logoUrl} alt="Logo" className="w-32 h-auto" />
        </div>

        {/* Create AI Character Button */}
        <Link onClick={toggleModal} className="flex items-center bg-gradient-to-r from-pink-500 via-white to-cyan-500 text-[#2C3E50] font-bold py-4 px-6 rounded-lg mx-auto mb-6 whitespace-nowrap text-lg w-full">
          <FaPlusCircle className="mr-3 text-[#2C3E50]" />
          Create AI Character
        </Link>
        <span className="text-white font-bold text-left text-2xl block mb-4">Assistant Ai</span>

{/* Assistant Ai */}
<Link to="/ai/1799990" className="flex items-center bg-[#202033] text-[#9E9CBF] font-bold py-2 px-2 rounded-md mb-2 transition-colors duration-300 hover:bg-[#282640] w-full">
          <div className="w-10 h-10 flex justify-center items-center bg-[#202033] rounded-md mr-2 overflow-hidden">
            <img 
              src="https://ugc-idle.s3-us-west-2.amazonaws.com/8d54b95844dc3676db35645720eedfd3.jpg" 
              alt="Assistant Ai Avatar" 
              className="w-full h-full object-cover"
            />
          </div>
          <span className="flex-grow text-left">Assistant Ai</span>
        </Link>
      {/* Featured Text Below Buttons */}
      <span className="text-white font-bold text-left text-2xl block mb-4">Featured</span>

      {/* Scrollable container for mobile */}
      <div className="overflow-y-auto lg:h-auto h-48">


        {/* Monkey D. Luffy */}
        <Link to="/ai/236755" className="flex items-center bg-[#202033] text-[#9E9CBF] font-bold py-2 px-2 rounded-md mb-2 transition-colors duration-300 hover:bg-[#282640] w-full">
          <div className="w-10 h-10 flex justify-center items-center bg-[#202033] rounded-md mr-2 overflow-hidden">
            <img 
              src="https://ugc-idle.s3-us-west-2.amazonaws.com/be4fffdf34043a88966c8afa5e3cc1c5.jpg" 
              alt="Monkey D. Luffy Avatar" 
              className="w-full h-full object-cover"
            />
          </div>
          <span className="flex-grow text-left">Monkey D. Luffy</span>
        </Link>

        {/* Hu Tao */}
        <Link to="/ai/930364" className="flex items-center bg-[#202033] text-[#9E9CBF] font-bold py-2 px-2 rounded-md mb-2 transition-colors duration-300 hover:bg-[#282640] w-full">
          <div className="w-10 h-10 flex justify-center items-center bg-[#202033] rounded-md mr-2 overflow-hidden">
            <img 
              src="https://exh-data.s3.us-west-2.amazonaws.com/cv/default_bots_metadata/v3/Hu%20Tao/avatar_256.jpg" 
              alt="Hu Tao Avatar" 
              className="w-full h-full object-cover"
            />
          </div>
          <span className="flex-grow text-left">Hu Tao</span>
        </Link>

        {/* 2B */}
        <Link to="/ai/268832" className="flex items-center bg-[#202033] text-[#9E9CBF] font-bold py-2 px-2 rounded-md mb-2 transition-colors duration-300 hover:bg-[#282640] w-full">
          <div className="w-10 h-10 flex justify-center items-center bg-[#202033] rounded-md mr-2 overflow-hidden">
            <img 
              src="https://exh-data.s3.us-west-2.amazonaws.com/cv/default_bots_metadata/v3/2B/avatar_256.jpg" 
              alt="2B Avatar" 
              className="w-full h-full object-cover"
            />
          </div>
          <span className="flex-grow text-left">2B</span>
        </Link>

        {/* Kobo Kanaeru */}
        <Link to="/ai/1799807" className="flex items-center bg-[#202033] text-[#9E9CBF] font-bold py-2 px-2 rounded-md mb-2 transition-colors duration-300 hover:bg-[#282640] w-full">
          <div className="w-10 h-10 flex justify-center items-center bg-[#202033] rounded-md mr-2 overflow-hidden">
            <img 
              src="https://s3.zerochan.net/240/39/14/3615739.avif" 
              alt="Kobo Kanaeru Avatar" 
              className="w-full h-full object-cover"
            />
          </div>
          <span className="flex-grow text-left">Kobo Kanaeru</span>
        </Link>

        {/* Nami */}
        <Link to="/ai/223756" className="flex items-center bg-[#202033] text-[#9E9CBF] font-bold py-2 px-2 rounded-md mb-2 transition-colors duration-300 hover:bg-[#282640] w-full">
          <div className="w-10 h-10 flex justify-center items-center bg-[#202033] rounded-md mr-2 overflow-hidden">
            <img 
              src="https://ugc-idle.s3-us-west-2.amazonaws.com/27c455ee848c643d2b248d88bb0e0c60.jpg" 
              alt="Nami Avatar" 
              className="w-full h-full object-cover"
            />
          </div>
          <span className="flex-grow text-left">Nami</span>
        </Link>

        {/* Karai */}
        <Link to="/ai/268799" className="flex items-center bg-[#202033] text-[#9E9CBF] font-bold py-2 px-2 rounded-md mb-2 transition-colors duration-300 hover:bg-[#282640] w-full">
          <div className="w-10 h-10 flex justify-center items-center bg-[#202033] rounded-md mr-2 overflow-hidden">
            <img 
              src="https://exh-data.s3.us-west-2.amazonaws.com/cv/default_bots_metadata/v3/Karai/avatar_256.jpg" 
              alt="Karai Avatar" 
              className="w-full h-full object-cover"
            />
          </div>
          <span className="flex-grow text-left">Karai</span>
        </Link>
      </div>
        {/* Spacer to push footer elements down */}
        <div className="flex-grow" />

        {/* Get Premium Button Above Social Icons */}
        <button
          onClick={toggleModal}
          className="flex items-center bg-gradient-to-r from-[#FF3C7D] to-[#00C1D6] text-white font-bold py-4 px-6 rounded-lg mx-auto mb-2 whitespace-nowrap text-lg w-full"
        >
          <FaCrown className="mr-3" />
          Get Premium
        </button>

        {/* Become a Creator Button Below Get Premium */}
        <Link onClick={toggleModal} className="flex items-center bg-gradient-to-r from-[#2A3F5A] to-[#332D5B] text-white font-bold py-4 px-6 rounded-lg mx-auto mb-4 whitespace-nowrap text-lg w-full">
          <div className="flex items-center">
            <FaStar className="text-3xl mr-4" />
            <div className="flex flex-col text-left">
              <span  className="text-xl">Become a Creator</span>
              <span className="text-sm">Earn real money</span>
            </div>
          </div>
        </Link>

        {/* Social Icons Row in Footer */}
        <div className="flex justify-between mb-2">
          <a href="https://telegram.org" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-10 h-10 bg-[#403E68] rounded-md hover:bg-[#5E5A88] transition-colors">
            <FaTelegram className="text-white" />
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-10 h-10 bg-[#403E68] rounded-md hover:bg-[#5E5A88] transition-colors">
            <FaTwitter className="text-white" />
          </a>
          <a href="https://discord.com" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-10 h-10 bg-[#403E68] rounded-md hover:bg-[#5E5A88] transition-colors">
            <FaDiscord className="text-white" />
          </a>
          <a href="https://medium.com" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-10 h-10 bg-[#403E68] rounded-md hover:bg-[#5E5A88] transition-colors">
            <FaMedium className="text-white" />
          </a>
          <a href="https://book.com" target="_blank" rel="noopener noreferrer" className="flex items-center justify-center w-10 h-10 bg-[#403E68] rounded-md hover:bg-[#5E5A88] transition-colors">
            <FaBook className="text-white" />
          </a>
        </div>

        {/* Copyright Notice at the Bottom */}
        <span className="text-[#403E68] text-sm text-center block">©NSFWAi 2024</span>
      </aside>

      {/* Main Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          style={{
            backdropFilter: 'blur(5px)',
            WebkitBackdropFilter: 'blur(5px)',
          }}
        >
          <div
            className="relative p-8 rounded-lg max-w-md w-full"
            style={{
              minHeight: '500px',  // Adjust height
              backgroundImage: 'url(https://botify.ai/static/media/bg.4c80dc254112ae3a7ef9.webp)', // Replace with your image URL
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              borderRadius: '16px',
              boxShadow: '0 4px 30px rgba(248, 125, 242, 0.7)', // Glowing effect
              border: '1px solid rgba(248, 125, 242, 0.7)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end', // Align content to the bottom
              paddingBottom: '40px', // Add some space at the bottom
              paddingTop: '80px', // Lower the image by adding padding at the top
            }}
          >
            <button
              className="absolute top-2 right-2 text-xl text-white"
              onClick={toggleModal}
            >
              <FaTimes />
            </button>
            <div className="p-4 bg-[#212034b0] rounded-lg">
              <h2 className="text-2xl font-bold text-center text-white">
                Premium Features
              </h2>
              <p className="mt-4 text-center text-white font-bold">
                Unlock premium features to enhance your experience.
              </p>

              {/* Plan Selection Buttons */}
              <div className="mt-6">
                <button
                  onClick={() => togglePriceModal('Premium')}
                  className="bg-pink-500 text-white font-bold py-2 px-4 rounded-lg mb-4 w-full"
                >
                  Choose Premium - $29.99/year
                </button>
                <button
                  onClick={() => togglePriceModal('Ultra')}
                  className="bg-cyan-500 text-white font-bold py-2 px-4 rounded-lg w-full"
                >
                  Choose Ultra - $79.99/year
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Price Modal */}
      {isPriceModalOpen && selectedPlan && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
          style={{
            backdropFilter: 'blur(5px)',
            WebkitBackdropFilter: 'blur(5px)',
          }}
        >
          <div
            className="relative p-8 rounded-lg max-w-sm w-full"
            style={{
              minHeight: '400px',  // Adjust height
              backgroundImage: 'url(https://botify.ai/static/media/bg.4c80dc254112ae3a7ef9.webp)', // Replace with your image URL
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              borderRadius: '16px',
              boxShadow: '0 4px 30px rgba(248, 125, 242, 0.7)', // Glowing effect
              border: '1px solid rgba(248, 125, 242, 0.7)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end', // Align content to the bottom
              paddingBottom: '40px', // Add some space at the bottom
              paddingTop: '80px', // Lower the image by adding padding at the top
            }}
          >
            <button
              className="absolute top-2 right-2 text-xl text-white"
              onClick={togglePriceModal}
            >
              <FaTimes />
            </button>
            <div className="p-4 bg-[#212034b0] rounded-lg">
              <h2 className="text-2xl font-bold text-center text-white">
                {selectedPlan} Plan
              </h2>
              <p className="mt-4 text-center text-white font-bold">
                {selectedPlan === 'Premium'
                  ? '$29.99/year'
                  : '$79.99/year'}
              </p>
              <ul className="space-y-2 mt-4 text-white font-bold">
              <li className="flex items-center">
                  <FaCheckCircle className="text-green-500 mr-2" />
                  Access to Create New Character
                </li>
                <li className="flex items-center">
                  <FaCheckCircle className="text-green-500 mr-2" />
                  Photos & Videos
                </li>
                <li className="flex items-center">
                  <FaCheckCircle className="text-green-500 mr-2" />
                  Unlimited Messages
                </li>
                <li className="flex items-center">
                  <FaCheckCircle className="text-green-500 mr-2" />
                  Private Bots
                </li>
                <li className="flex items-center">
                      <FaCheckCircle className="text-green-500 mr-2" />
                      Become a Creator
                    </li>
                {selectedPlan === 'Ultra' && (
                  <>
                    <li className="flex items-center">
                      <FaCheckCircle className="text-green-500 mr-2" />
                      Role-Playing
                    </li>
                    <li className="flex items-center">
                      <FaCheckCircle className="text-green-500 mr-2" />
                      Tailor-made AI Models
                    </li>
                    
                  </>
                )}
              </ul>
              <button
                onClick={togglePriceModal}
                className="mt-6 bg-gradient-to-r from-pink-500 to-cyan-500 text-white font-bold py-2 px-4 rounded-lg w-full"
              >
                Confirm Purchase
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
