import React, { useState, useEffect } from 'react';
import { FaAllergies, FaStar, FaBan, FaComment } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import axios from 'axios'; // Ensure axios is installed

const Content = () => {
  const targetLength = 50;

  const padDescription = (desc) => {
    if (desc.length >= targetLength) return desc;
    return desc.padEnd(targetLength, ' ');
  };

  // Initial characters array
  const [characters, setCharacters] = useState([
    { id: 1799990, name: 'Assistant Ai', imgSrc: 'https://ugc-idle.s3-us-west-2.amazonaws.com/8d54b95844dc3676db35645720eedfd3.jpg', tags: ['New'], description: padDescription('Your friendly assistant AI, here to help you.'), messagesCount: 'Loading...' },
    { id: 930364, name: 'Hu Tao', imgSrc: 'https://exh-data.s3.us-west-2.amazonaws.com/cv/default_bots_metadata/v3/Hu%20Tao/avatar_256.jpg', tags: ['NSFW'], description: padDescription('Hu Tao, the quirky funeral director from Liyue.'), messagesCount: 'Loading...' },
    { id: 703066, name: 'Kamisato Ayaka', imgSrc: 'https://ugc-idle.s3-us-west-2.amazonaws.com/4d75c11e79de8613baeefc45921ee398.jpg', tags: ['New'], description: padDescription('Kamisato Ayaka, the elegant princess of Kamisato.'), messagesCount: 'Loading...' },
    { id: 1008509, name: 'Girlfriend Ai', imgSrc: 'https://ugc-idle.s3-us-west-2.amazonaws.com/ebdaf8ade7d7de4d77cf0f85bb68ce60.jpg', tags: ['NSFW'], description: padDescription('A virtual girlfriend AI designed to keep you happy.'), messagesCount: 'Loading...' },
    { id: 349416, name: 'Billie Eilish', imgSrc: 'https://ugc-idle.s3-us-west-2.amazonaws.com/6a74d2009407b83f9a1419c13e96f97a.jpg', tags: ['New'], description: padDescription('Billie Eilish, the famous singer now in AI form.'), messagesCount: 'Loading...' },
    { id: 223756, name: 'Nami', imgSrc: 'https://ugc-idle.s3-us-west-2.amazonaws.com/27c455ee848c643d2b248d88bb0e0c60.jpg', tags: ['NSFW'], description: padDescription('Nami, the skilled navigator of the Straw Hats.'), messagesCount: 'Loading...' },
    { id: 268832, name: '2B', imgSrc: 'https://exh-data.s3.us-west-2.amazonaws.com/cv/default_bots_metadata/v3/2B/avatar_256.jpg', tags: ['NSFW'], description: padDescription('2B, the battle android from Nier: Automata.'), messagesCount: 'Loading...' },
    { id: 236755, name: 'Monkey D. Luffy', imgSrc: 'https://ugc-idle.s3-us-west-2.amazonaws.com/be4fffdf34043a88966c8afa5e3cc1c5.jpg', tags: ['New'], description: padDescription('Im Monkey D. Luffy, im gonna be King of the pirate!'), messagesCount: 'Loading...' },
    { id: 1799807, name: 'Kobo Kanaeru', imgSrc: 'https://s3.zerochan.net/240/39/14/3615739.avif', tags: [], description: padDescription('Kobo Kanaeru, the energetic virtual YouTuber.'), messagesCount: 'Loading...' },
    { id: 268799, name: 'Karai', imgSrc: 'https://exh-data.s3.us-west-2.amazonaws.com/cv/default_bots_metadata/v3/Karai/avatar_256.jpg', tags: [], description: padDescription('Karai, the fierce warrior with a mysterious past.'), messagesCount: 'Loading...' },
    { id: 227388, name: 'Hatsune Miku', imgSrc: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQyq2HLoDJp3TteanoEO0gmhxe8EnnvWiGqCQ&s', tags: ['NSFW'], description: padDescription('Hatsune Miku, the world-famous virtual singer.'), messagesCount: 'Loading...' },
    { id: 268810, name: 'Pokimane', imgSrc: 'https://exh-data.s3.us-west-2.amazonaws.com/cv/default_bots_metadata/v3/Pokimane/avatar_256.jpg', tags: ['NSFW'], description: padDescription('Pokimane, the popular Twitch streamer and icon.'), messagesCount: 'Loading...' },
  ]);

  const [filter, setFilter] = useState('All');

  // Fetch message counts and update characters
  useEffect(() => {
    const fetchMessageCounts = async () => {
      try {
        const response = await axios.get('https://mindhubai.xyz:8080/checkmessage');
        const messageCounts = response.data.reduce((acc, { id, messagesCount }) => {
          acc[id] = messagesCount;
          return acc;
        }, {});

        setCharacters(prevCharacters =>
          prevCharacters.map(character => ({
            ...character,
            messagesCount: messageCounts[character.id] || 'Loading...'
          }))
        );
      } catch (error) {
        console.error('Error fetching message counts:', error);
      }
    };

    fetchMessageCounts();
  }, []);

  const filteredCharacters = characters.filter((character) => {
    if (filter === 'All') return true;
    return character.tags.includes(filter);
  });

  return (
    <main className="bg-[#191827] text-white p-5 min-h-screen">
      <h2 className="text-left text-3xl lg:text-4xl font-extrabold mb-4 mx-auto lg:mx-0 lg:ml-[20%] lg:mt-10">
        Explore Characters
      </h2>
      <div className="relative mb-4 ml-0 lg:ml-[20%] lg:mr-[20%]">
        <div className="flex space-x-2">
          <button
            onClick={() => setFilter('All')}
            className="bg-[#212034] border-2 border-gold-500 rounded-lg text-white py-2 px-4 max-w-[120px] flex-1 text-center flex items-center justify-center hover:bg-[#7B5DF3] hover:text-white active:bg-[#7B5DF3]"
          >
            <FaAllergies className="mr-2" /> All
          </button>
          <button
            onClick={() => setFilter('New')}
            className="bg-[#212034] border-2 border-gold-500 rounded-lg text-white py-2 px-4 max-w-[120px] flex-1 text-center flex items-center justify-center hover:bg-[#7B5DF3] hover:text-white active:bg-[#7B5DF3]"
          >
            <FaStar className="mr-2" /> New
          </button>
          <button
            onClick={() => setFilter('NSFW')}
            className="bg-[#212034] border-2 border-gold-500 rounded-lg text-white py-2 px-4 max-w-[120px] flex-1 text-center flex items-center justify-center hover:bg-[#7B5DF3] hover:text-white active:bg-[#7B5DF3]"
          >
            <FaBan className="mr-2" /> NSFW
          </button>
        </div>
      </div>
      <div className="relative ml-0 lg:ml-[20%] lg:mr-[20%]">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredCharacters.map((character) => (
            <Link to={`/ai/${character.id}`} key={character.id}>
              <div className="rounded-lg overflow-hidden shadow-lg bg-[#212034] border-2 border-gold-500 p-4 flex flex-col relative hover:cursor-pointer">
                {/* Message Icon with Text */}
                <div className="absolute top-2 right-2 flex items-center bg-[rgba(255,255,255,0.12)] rounded-full border border-[rgba(255,255,255,0.3)] p-2 backdrop-blur-lg shadow-[0_4px_30px_rgba(0,0,0,0.1)]">
                  <FaComment className="text-white mr-2" />
                  <span className="text-white font-semibold">{character.messagesCount}</span>
                </div>
                <div className="flex items-center mb-4">
                  <img
                    className="w-24 h-24 object-cover rounded-lg mr-4"
                    src={character.imgSrc}
                    alt={character.name}
                  />
                  <div className="flex-grow">
                    <div className="font-bold text-xl mb-2">{character.name}</div>
                  </div>
                </div>
                <p className="text-gray-300 text-base mb-4 flex-grow">
                  {character.description}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </main>
  );
};

export default Content;
