import React, { useState, useEffect } from 'react';
import { FaSearch, FaQrcode, FaBars } from 'react-icons/fa';

const Header = ({ toggleSidebar, isSticky = true }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [walletAddress, setWalletAddress] = useState(() => {
    // Retrieve the wallet address from localStorage on initial render
    return localStorage.getItem('walletAddress') || null;
  });
  const logoUrl = 'https://botify.ai/static/media/header-logo.edb9aa93e7af1b9b061ba3ef91ca777f.svg';

  const handleScroll = () => {
    if (isSticky) {
      setIsScrolled(window.scrollY > 50);
    }
  };

  useEffect(() => {
    if (isSticky) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [isSticky]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const connectWallet = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const address = accounts[0];
        setWalletAddress(address); // Set the wallet address
        localStorage.setItem('walletAddress', address); // Save the wallet address in localStorage
        setIsModalOpen(false); // Close the modal after connecting
      } catch (error) {
        console.error("Error connecting to wallet:", error);
      }
    } else {
      alert('MetaMask is not installed. Please install it to use this feature.');
    }
  };

  // Function to shorten wallet address
  const shortenAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  return (
    <header
      className={`bg-[#292942] p-4 flex items-center justify-between h-16 transition-all duration-300 ease-in-out ${
        isSticky && isScrolled ? 'fixed top-0 left-0 right-0 shadow-md z-50' : 'relative'
      }`}
    >
      <button
        className="text-3xl lg:hidden"
        onClick={toggleSidebar}
        style={{ color: '#7C5DF3' }}
      >
        <FaBars />
      </button>
      <div className="flex-grow lg:flex lg:justify-start lg:items-center">
        <img
          src={logoUrl}
          alt="Logo"
          className="w-24 h-auto lg:w-28 lg:h-auto mx-auto lg:mx-0"
        />
      </div>
      <div className="lg:hidden flex items-center">
        <div className="relative">
          <button 
            className="flex items-center justify-center bg-[#332E5B] text-white rounded-full w-12 h-12 hover:bg-[#332E5B] transition"
            onClick={toggleModal}
          >
            <img 
              src="https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg" 
              alt="Connect Wallet" 
              className="w-6 h-6" 
            />
          </button>
          
          {/* Modal */}
          {isModalOpen && (
            <div className="absolute right-0 transform translate-x-2 mt-1 bg-[#000000] border border-[#FFFFFF] text-white rounded-lg shadow-lg py-4 px-6 z-50 w-48">
              <div className="relative">
                <div className="absolute right-0 transform translate-x-1 -top-2 w-3 h-3 bg-[#000000] rotate-45 border border-[#FFFFFF]"></div>
                <button 
                  className="flex items-center justify-center bg-[#000000] border border-[#FFFFFF] text-white rounded-lg px-4 py-2 w-full"
                  onClick={connectWallet} // Connect wallet on button click
                >
                  <img 
                    src="https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg" 
                    alt="MetaMask" 
                    className="w-5 h-5 mr-2" 
                  />
                  {walletAddress ? shortenAddress(walletAddress) : 'Connect Wallet'}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="hidden lg:flex items-center relative">
        <button className="absolute left-4" style={{ color: '#5A5976' }}>
          <FaSearch />
        </button>
        <input
          type="text"
          placeholder="Search by name"
          className="bg-[#191827] text-[#5A5976] pl-12 pr-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#7C5DF3] transition placeholder:text-[#5A5976]"
          style={{ width: '350px', height: '44px' }}
        />
        <button className="flex items-center justify-center ml-4 bg-gradient-to-r from-pink-500 to-cyan-500 text-white font-bold py-2 px-6 rounded-lg shadow-md hover:opacity-90 transition" style={{ height: '44px', minWidth: '170px' }}>
          <FaQrcode className="mr-2" />
          Get App
        </button>
        <div className="ml-4 flex items-center">
          <button className="flex items-center justify-center bg-gradient-to-r from-[#2B3F5A] to-[#332E5B] text-white rounded-full py-2 px-4 hover:opacity-90 transition" onClick={connectWallet}>
            <img src="https://upload.wikimedia.org/wikipedia/commons/3/36/MetaMask_Fox.svg" alt="Connect Wallet" className="mr-2 w-5 h-5" />
            {walletAddress ? shortenAddress(walletAddress) : 'Connect Wallet'}
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
